import _groupBy from "lodash/groupBy";

import { convertToDateAndWeekdayLiterary } from "@/utils/dateUtils";
import {
  getBalanceAndPaymentData_getBillingEvents_edges,
} from "@components/profile/balance/graphql/__generated__/getBalanceAndPaymentData";

export const groupedEvents = (events: any) =>
  _groupBy(
    events?.edges.filter((event: any) =>
      !!event),

    (event: getBalanceAndPaymentData_getBillingEvents_edges) =>
      convertToDateAndWeekdayLiterary(event.node.timestamp),
  );

export const getExchangeRate = (amount: number, rate: number) => {
  const result = (amount / rate).toFixed(2); // Форматируем число с двумя знаками после запятой

  return result;
};
