import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { groupedEvents } from "@components/profile/utils";

import { PAYMENT_HISTORY_PAGE_SIZE } from "../constants";
import {
  getBalanceAndPaymentData_getBillingEvents,
  getBalanceAndPaymentData_getBillingEvents_edges,
} from "../graphql/__generated__/getBalanceAndPaymentData";
import {
  getMoreEvents as getMoreEventsType,
  getMoreEventsVariables as getMoreEventsVariablesType,
} from "../graphql/__generated__/getMoreEvents";
import { GET_MORE_EVENTS } from "../graphql/GET_MORE_EVENTS";

import PaymentEvent from "./paymentEvent";
import { PaymentHistoryProps } from "./types";

import "./styles.scss";

const PaymentHistory = ({ eventsInitial }: PaymentHistoryProps) => {
  const [events, setEvents] = useState<getBalanceAndPaymentData_getBillingEvents>();
  const [currentPage, setCurrentPage] = useState(1);
  const [getMoreEvents, { data: loadMoreData, loading: loadMoreLoading, error: loadMoreError },
  ] = useLazyQuery<getMoreEventsType, getMoreEventsVariablesType>(GET_MORE_EVENTS);

  useEffect(() => {
    if (eventsInitial) {
      setEvents(eventsInitial);
    }
  }, [eventsInitial]);

  const onLoadMoreClick = () => {
    if (events?.pageInfo.endCursor !== "") {
      getMoreEvents({
        variables: {
          first: PAYMENT_HISTORY_PAGE_SIZE,
          after: events?.pageInfo.endCursor,
          sorting: {
            sortBy: SortByEnum.id,
            order: OrderEnum.desc,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (loadMoreData && !loadMoreLoading && !loadMoreError) {
      setEvents({
        ...loadMoreData.getBillingEvents,
        edges: events?.edges.concat(loadMoreData.getBillingEvents.edges) ?? [],
        pageInfo: loadMoreData.getBillingEvents.pageInfo,
      });
      setCurrentPage((oldVal) =>
        oldVal + 1);
    }
  }, [loadMoreData, loadMoreLoading, loadMoreError]);

  if (!events || events.totalCount === 0) {
    return (
      <div className="payment-history">
        <h2 className="payment-history__header">История платежей</h2>
        <div className="payment-history__empty">
          <Icon type={IconTypeEnum.Search} size={IconSizeEnum.Size32} />
          <div className="empty__text">Платежей пока не&nbsp;было</div>
        </div>
      </div>
    );
  }

  const remainingCount = (events.totalCount ?? 0) > currentPage * PAYMENT_HISTORY_PAGE_SIZE
    ? (events.totalCount ?? 0) - currentPage * PAYMENT_HISTORY_PAGE_SIZE
    : 0;

  return (
    <div className="payment-history">
      <h2 className="payment-history__header">История платежей</h2>
      {Object.keys(groupedEvents(events)).map((key) =>
        (
          <div key={key}>
            <div className="payment-history__date">{key}</div>
            {groupedEvents(events)[key].map(
              (event: getBalanceAndPaymentData_getBillingEvents_edges) =>
                (
                  <PaymentEvent event={event.node} key={event.node.id} />
                ),
            )}
          </div>
        ))}
      {events.pageInfo.hasNextPage && (
        <Button
          text={`Показать ещё ${Math.min(remainingCount, PAYMENT_HISTORY_PAGE_SIZE)}`}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.White}
          onClick={() =>
            onLoadMoreClick()}
          isLoading={loadMoreLoading}
          className="payment-history__button"
        />
      )}
    </div>
  );
};

export default PaymentHistory;
