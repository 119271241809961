import React from "react";

import { CardType } from "@/autoGeneratedGlobalTypes";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { CardIconProps } from "./types";

const CardIcon = ({ type, size }: CardIconProps) => {
  switch (type) {
    case CardType.Mastercard:
      return <Icon type={IconTypeEnum.PayMastercard} size={size ?? IconSizeEnum.Size24} />;
    case CardType.VISA:
      return <Icon type={IconTypeEnum.PayVisa} size={size ?? IconSizeEnum.Size24} />;
    case CardType.Mir:
      return <Icon type={IconTypeEnum.PayMir} size={size ?? IconSizeEnum.Size24} />;
    default:
      return <></>;
  }
};

export default CardIcon;
