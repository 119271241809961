import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";

import { Dropdown, DropdownSizeEnum, DropdownTypeEnum } from "@/components/common/dropdown";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { cardTypeToString } from "@/utils/globalTypesUtils";
import { priceTo2DigitsString } from "@/utils/numberUtils";

import {
  deleteCard as deleteCardType,
  deleteCardVariables as deleteCardVariablesType,
} from "../graphql/__generated__/deleteCard";
import {
  getBalanceAndPaymentData_getUserCards,
} from "../graphql/__generated__/getBalanceAndPaymentData";
import { getUserCards as getUserCardsType } from "../graphql/__generated__/getUserCards";
import {
  setDefaultCard as setDefaultCardType,
  setDefaultCardVariables as setDefaultCardVariablesType,
} from "../graphql/__generated__/setDefaultCard";
import { DELETE_CARD } from "../graphql/DELETE_CARD";
import { GET_USER_CARDS } from "../graphql/GET_USER_CARDS";
import { SET_DEFAULT_CARD } from "../graphql/SET_DEFAULT_CARD";

import CardIcon from "./cardIcon";
import { PaymentMethodsProps } from "./types";

import "./styles.scss";

const PaymentMethods = (props: PaymentMethodsProps) => {
  const { cards: cardsData } = props;
  const [setDefaultCard] = useMutation<setDefaultCardType, setDefaultCardVariablesType>(SET_DEFAULT_CARD, {
    refetchQueries: [
      {
        query: GET_USER_CARDS,
      },
    ],
  });
  const [deleteCard, {
    data: deleteCardData,
    loading: deleteCardLoading,
    error: deleteCardError,
  }] = useMutation<deleteCardType, deleteCardVariablesType>(DELETE_CARD);
  const [
    getUserCards,
    {
      data: getUserCardsData,
      loading: getUserCardsLoading,
      error: getUserCardsError,
    },
  ] = useLazyQuery<getUserCardsType>(GET_USER_CARDS, { fetchPolicy: "network-only" });
  const [cards, setCards] = useState<(getBalanceAndPaymentData_getUserCards | null)[]>([]);
  const [checkedCardId, setCheckedCardId] = useState<number | undefined>();

  useEffect(() => {
    setCards(cardsData);
  }, [cardsData]);

  useEffect(() => {
    setCheckedCardId(cards.find((card) =>
      card?.isDefault)?.id);
  }, [cards]);

  const handleDefaultCardChange = (cardId: number) => {
    setDefaultCard({ variables: { cardID: cardId } }); // todo: maybe check result status
    setCheckedCardId(cardId);
  };

  const handleDeleteClick = (cardId: number) => {
    deleteCard({ variables: { cardID: cardId } });
  };

  useEffect(() => {
    if (deleteCardData && !deleteCardLoading && !deleteCardError) {
      getUserCards();
    }
  }, [deleteCardData, deleteCardLoading, deleteCardError]);

  useEffect(() => {
    if (getUserCardsData && !getUserCardsLoading && !getUserCardsError) {
      setCards(getUserCardsData.getUserCards);
    }
  }, [getUserCardsData, getUserCardsLoading, getUserCardsError]);

  return (
    <div className="payment-methods">
      <h2 className="payment-methods__header">Способ оплаты</h2>
      <p className="payment-methods__text">Выберите способ оплаты по умолчанию</p>
      <div className="payment-methods__hynt">
        <Icon type={IconTypeEnum.Lock} size={IconSizeEnum.Size20} />
        <span>Карты надежно хранятся в зашифрованном виде</span>
      </div>
      {cards && cards.length > 0 ? (
        cards.map((card) =>
          (card ? (
            <div className="payment-method" key={card.id}>
              <div className="payment-method__left">
                <div className="payment-method__icon">
                  <CardIcon type={card.type} />
                </div>
              </div>
              <div className="payment-method__info">
                <div className="payment-method__card-data">
                  {cardTypeToString(card.type)}
                  {" "}
                  •
                  {card.number}
                </div>
                <div className="payment-method__expiry-date">
                  До
                  {" "}
                  {priceTo2DigitsString(card.expiryMonth)}
                  /
                  {card.expiryYear}
                </div>
                <div className="payment-method__change-link">
                  <Dropdown
                    options={[
                      {
                        value: card.id.toString(),
                        label: (
                          <label className="payment-method__change-option">
                            <Icon type={IconTypeEnum.Delete} size={IconSizeEnum.Size24} />
                            <span>Удалить карту</span>
                          </label>
                        ),
                      },
                    ]}
                    value={{
                      value: "Изменить",
                      label: "Изменить",
                    }}
                    onChange={() =>
                      handleDeleteClick(card.id)}
                    size={DropdownSizeEnum.Small}
                    type={DropdownTypeEnum.Text}
                  />
                </div>
              </div>
              <div className="payment-method__right">
                <input
                  className="big"
                  type="radio"
                  name="payment"
                  checked={card.id === checkedCardId}
                  onChange={() =>
                    handleDefaultCardChange(card.id)}
                />
              </div>
            </div>
          ) : null))
      ) : (
        <div className="payment-history__empty">
          <Icon type={IconTypeEnum.Search} size={IconSizeEnum.Size32} />
          <div className="empty__text">Нет сохранённых способов оплаты</div>
          <div className="empty__subtext">Вы сможете добавить их при&nbsp;заказе консультации</div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
