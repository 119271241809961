import { gql } from "@apollo/client";

export const GET_USER_CARDS = gql`
  query getUserCards {
    getUserCards {
      id
      number
      type
      expiryMonth
      expiryYear
      isDefault
    }
  }
`;
