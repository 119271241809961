import { gql } from "@apollo/client";

export const GET_BALANCE_PAGE_DATA = gql`
  query getBalanceAndPaymentData($eventCount: Int, $eventSorting: SortParameters) {
    getMyProfile {
      balance {
        amount
        currency
      }
      isExpert
    }
    getUserCards {
      id
      number
      type
      expiryMonth
      expiryYear
      isDefault
    }
    getBillingEvents(first: $eventCount, sorting: $eventSorting) {
      edges {
        node {
          id
          timestamp
          type
          session {
            sessionDuration
            amount {
              amount
              currency
            }
          }
          payment {
            amount
            currency
          }
          correction {
            amount
            currency
          }
          bonus {
            amount
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
