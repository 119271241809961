import { gql } from "@apollo/client";

export const GET_MORE_EVENTS = gql`
  query getMoreEvents($first: Int, $after: String, $sorting: SortParameters) {
    getBillingEvents(first: $first, after: $after, sorting: $sorting) {
      edges {
        node {
          id
          timestamp
          type
          session {
            sessionDuration
            amount {
              amount
              currency
            }
          }
          payment {
            amount
            currency
          }
          correction {
            amount
            currency
          }
          bonus {
            amount
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
