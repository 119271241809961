import React, { useContext, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";

import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";
import Loader from "@/components/common/loader";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { marketingTrackFlocktoryExchange, marketingTrackGet4clickExchange } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";

import BalanceBlock from "./balanceBlock";
import { PAYMENT_HISTORY_PAGE_SIZE } from "./constants";
import {
  getBalanceAndPaymentData,
  getBalanceAndPaymentDataVariables,
} from "./graphql/__generated__/getBalanceAndPaymentData";
import { GET_BALANCE_PAGE_DATA } from "./graphql/GET_BALANCE_PAGE_DATA";
import PaymentHistory from "./paymentHistory";
import PaymentMethods from "./paymentMethods";
import { BalanceProps } from "./types";
import "./styles.scss";

const Balance = ({ isSuccessPayment }: BalanceProps) => {
  const {
    isUserLoggedIn,
    setBalance,
    email,
  } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);

  useEffect(() => {
    if (isSuccessPayment && isBrowser() && email !== undefined) {
      marketingTrackGet4clickExchange(email || "");
      marketingTrackFlocktoryExchange(email || "xname@flocktory.com");
    }
  }, [email, isSuccessPayment]);

  const {
    data,
    error,
    loading,
  } = useQuery<getBalanceAndPaymentData, getBalanceAndPaymentDataVariables>(
    GET_BALANCE_PAGE_DATA,
    {
      variables: {
        eventCount: PAYMENT_HISTORY_PAGE_SIZE,
        eventSorting: {
          sortBy: SortByEnum.id,
          order: OrderEnum.desc,
        },
      },
      fetchPolicy: "cache-and-network",
    },
  );

  useEffect(() => {
    if (!isUserLoggedIn || isExpert) {
      if (isBrowser()) {
        navigate("/", { replace: true });
      }
    }
  }, [isExpert, isUserLoggedIn]);

  useEffect(() => {
    if (data && !loading && !error) {
      setBalance(data.getMyProfile.balance);
    }
  }, [data, loading, error, setBalance]);

  return (
    <div className="balance">
      <h1 className="balance__header">Баланс и платежи</h1>
      {/* todo: replace with skeleton */}
      {loading && <Loader />}
      {data && (
        <>
          {/* For nested routes state passed via navigate() doesn't store in component state,
              but in window.history.state instead https://github.com/gatsbyjs/gatsby/issues/9091 */}
          <BalanceBlock
            showPaymentAnimation={window.history.state?.showPaymentAnimation ?? false}
          />
          <PaymentMethods cards={data?.getUserCards ?? []} />
          <PaymentHistory eventsInitial={data?.getBillingEvents} />
        </>
      )}
    </div>
  );
};

export default Balance;
