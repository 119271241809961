import React, {
  useContext, useEffect, useState,
} from "react";

import { navigate } from "gatsby";

import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import Confetti from "@/components/common/confetti";
import FreeMinutesDisplay from "@/components/common/freeMinutesDisplay";
import { IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import Loader from "@/components/common/loader";
import { Tooltip, TooltipPositionEnum } from "@/components/common/tooltip";
import UserContext from "@/contexts/User/UserContext";
import { currencyToString } from "@/utils/globalTypesUtils";
import { priceToString } from "@/utils/numberUtils";
import { cleanupFromTags } from "@/utils/stringUtils";

import { BalanceBlockProps } from "./types";
import "./styles.scss";

const BalanceBlock = ({ showPaymentAnimation = false }: BalanceBlockProps) => {
  const {
    balance,
    unfinishedPaymentId,
  } = useContext(UserContext);

  const [showSuccessPaymentAnimation, setShowSuccessPaymentAnimation] = useState(false);

  const handlePayClick = () => {
    navigate("/profile/payment/");
  };

  useEffect(() => {
    if (showPaymentAnimation && !unfinishedPaymentId) {
      setShowSuccessPaymentAnimation(true);
    }
  }, [showPaymentAnimation, unfinishedPaymentId]);

  if (!balance) {
    return null;
  }

  return (
    <div className="balance-block__container">
      <div className="balance-block">
        {showSuccessPaymentAnimation && <Confetti />}
        <div className="balance-block__card">
          <div>Ваш баланс</div>
          <h1 className="balance-block__balance">
            {unfinishedPaymentId ? (
              <Loader />
            ) : (
              <>
                <div>
                  <span
                    className="balance-block__amount"
                    dangerouslySetInnerHTML={{
                      __html: cleanupFromTags(priceToString(balance.amount)
                        .replace(" ", "&nbsp;")),
                    }}
                  />
                &nbsp;
                  <span className="balance-block__currency">
                    {currencyToString(balance.currency)}
                  </span>
                </div>
                <div className="balance-block__gift-minutes-block">
                  <FreeMinutesDisplay iconSize={IconSizeEnum.Size24} withMinutes />
                  <Tooltip position={TooltipPositionEnum.Top} text="Бесплатные минуты" isHoverEvent>
                    <p className="balance-block__tooltip-text">Что это?</p>
                  </Tooltip>
                </div>
              </>
            )}
          </h1>
          <Button
            text="Пополнить баланс"
            icon={IconTypeEnum.Plus}
            iconPosition={ButtonIconPositionEnum.Left}
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Black}
            onClick={handlePayClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BalanceBlock;
