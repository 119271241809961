import * as React from "react";

import Balance from "@/components/profile/balance";
import ProfileLayout from "@/components/profile/profileLayout";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

interface BalancePageProps {
  location : {
    state: null | {
      isSuccessPayment: boolean
    }
  }
}

const BalancePage = ({ location }: BalancePageProps) => {
  if (!isBrowser()) {
    return null;
  }

  const { isSuccessPayment } = location?.state || {};

  return (
    <ProfileLayout>
      <Balance isSuccessPayment={isSuccessPayment} />
    </ProfileLayout>
  );
};

export default BalancePage;
